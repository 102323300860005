import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import * as AcfLayout from '../acf';
import { decodeEntities } from '../utils/htmlParse';
import { RelatedPostsBlock } from '../acf/RelatedPostsBlock';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';

const AcfComponent = ({ post, item, location, wordpressUrl, siteUrl, componentName }) => {
  const ComponentName = AcfLayout[componentName];
  return (
    <ComponentName
      post={post}
      location={location}
      wordpressUrl={wordpressUrl}
      siteUrl={siteUrl}
      {...item}
    />
  );
};

export const BlogPostTemplate = ({
  content,
  title,
}) => {
  return (
    <section className="default-content">
      <div className="wrap">
        <h2 className="title" dangerouslySetInnerHTML={{__html: title}} />
        <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </section>
  )
}

const BlogPost = ({ data, location, pageContext }) => {
  const { wordpressPost: post, site, relatedPosts } = data;
  if (!post) return null;
  const { title, content, yoast, acf, categories } = post;
  const { layout } = acf;
  const { previous: previousPost, next: nextPost } = pageContext;
  const { title: siteTitle, wordpressUrl, blogSlug, siteUrl } = site.siteMetadata;
  // const primaryCategory = categories[0].wordpress_id;
  return (
    <Layout>
      <SEO
        title={yoast.metaTitle ? `${decodeEntities(yoast.metaTitle)}` : `${decodeEntities(title)} | ${siteTitle}`}
        desc={yoast.metaDescription}
      />
      {layout ? (
        <>
          {layout.map(item => {
            const layoutComponentName = item.name.replace('WordPressAcf_','');
            return (
              <AcfComponent
                post={post}
                key={item.id}
                componentName={layoutComponentName}
                item={item}
                siteUrl={siteUrl}
                location={location}
                blogSlug={blogSlug}
                wordpressUrl={wordpressUrl}
              />
            );
          })}
          {(nextPost || previousPost) && (
            <section className="page-navigation-block simple-on-mobile">
              <div className="wrap">
                <div className={`left-link ${previousPost ? 'visible' : ''}`}>
                  {previousPost && (
                    <Link to={`/${blogSlug}/${previousPost.slug}/`} aria-label="Previous Article">
                      <span className="link-label">Previous Article</span>
                      {previousPost.title && <span className="headline" dangerouslySetInnerHTML={{__html: previousPost.title}} />}
                    </Link>
                  )}
                </div>
                <div className={`right-link ${nextPost ? 'visible' : ''}`}>
                  {nextPost && nextPost.slug !== 'dummy-post' && (
                    <Link to={`/${blogSlug}/${nextPost.slug}/`} aria-label="Next Article">
                      <span className="link-label">Next Article</span>
                      {nextPost.title && <span className="headline" dangerouslySetInnerHTML={{__html: nextPost.title}} />}
                    </Link>
                  )}
                </div>
              </div>
            </section>
          )}
          {relatedPosts && relatedPosts.edges && relatedPosts.edges.length > 0 && (
            <RelatedPostsBlock
              id="post-related-posts"
              title="Related"
              posts={relatedPosts.edges}
            />
          )}
        </>
      ) : <BlogPostTemplate title={title} content={content} />
      }
    </Layout>
  )
}

export default Previewable(BlogPost);

export const pageQuery = graphql`
  # fragment PostFields on wordpress__POST {
  #   id
  #   slug
  #   content
  #   date(formatString: "DD.MM.YYYY")
  #   title
  # }
  query BlogPostByID($id: String!, $primaryCategoryId: Int) {
    site {
      siteMetadata {
        title
        wordpressUrl
        siteUrl
        blogSlug
        functionsUrl
      }
    }
    wordpressPost(id: { glob: $id }) {
      id
      title
      slug
      content
      date(formatString: "DD.MM.YYYY")
      categories {
        wordpress_id
        name
        slug
      }
      author {
        name
        slug
        description
        meta {
          twitter
          googleplus
        }
        avatar_urls {
          wordpress_96
        }
      },
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
      acf {
        layout: layout_post {
          name: __typename,
          ... on WordPressAcf_ArticleIntro {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            subtitle
            bodyText
          }
          ... on WordPressAcf_ArticleTextBlock {
            numColumns
            columnOneText
            columnTwoText
          }
          ... on WordPressAcf_CallToAction {
            id
            theme
            type
            title
            headline
            bodyText
            hasButton
            buttonLabel
            buttonLink
            quoteeTitle
            quoteeName
          },
        }
      }
    }
    relatedPosts: allWordpressPost(filter: {categories: {elemMatch: {wordpress_id: {eq: $primaryCategoryId}}}, id: {ne: $id}}) {
      edges {
        node {
          slug
          title
          excerpt
          date(formatString: "DD.MM.YYYY")
          author {
            name
          }
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          categories {
            slug
            name
          }
        }
      }
    }
  }
`
